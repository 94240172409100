import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useMount } from "react-use";
import { useTabItem } from "../../../../../store/editor";
import { useMemo } from "react";
import { Box, Breadcrumbs, Typography } from "@mui/material";
import HeaderWithBackBtn from "../../../../../components/HeaderWithBackBtn";
import Question from "./Question";
import Block from "./Block";
import { useState } from "react";

const TabItem = () => {
  const { id, itemId, tab } = useParams();
  const tabItem = useTabItem(tab, itemId);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [guardCheck, setGuardCheck] = useState(false);

  const backLink = useMemo(() => {
    const from = searchParams.get("from");

    return from
      ? `/surveys/${id}/editor/blocks/${from}`
      : `/surveys/${id}/editor/${tab}`;
  }, [id, tab, searchParams]);

  useMount(() => {
    if (!tabItem) navigate(`/surveys/${id}/editor/${tab}`);

    setGuardCheck(true);
  });

  return (
    <>
      {guardCheck && (
        <>
          <Box sx={{ py: 1 }}>
            <HeaderWithBackBtn
              header={
                <Breadcrumbs>
                  <Typography
                    component={Link}
                    to={`/surveys/${id}/editor/${tab}`}
                    sx={{
                      color: "primary.main",
                      fontWeight: 500,
                      textDecoration: "none",
                      "&:hover": {
                        color: "primary.light",
                        textDecoration: "underline",
                      },
                    }}
                  >
                    {tab.charAt(0).toUpperCase() + tab.slice(1)}
                  </Typography>

                  <Typography color="text.primary" sx={{ fontWeight: 500 }}>
                    {tabItem?.name}
                  </Typography>
                </Breadcrumbs>
              }
              redirect={backLink}
            />
          </Box>

          {tab === "blocks" && <Block />}

          {tab === "questions" && <Question />}
        </>
      )}
    </>
  );
};

export default TabItem;
