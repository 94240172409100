import { Alert, Button, Dialog, Stack, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { API } from "aws-amplify";
import getJwt from "../../../helpers/getJwt";
import useSurveysStore from "../../../store/surveys";
import useBaseStore from "../../../store/base";
import SurveyForm from "../../../components/SurveyForm";
import { LoadingButton } from "@mui/lab";
import { Warning } from "@mui/icons-material";

const General = () => {
  const { surveys, currentSurvey, setCurrentSurvey, setSurveys } =
    useSurveysStore();
  const { setSnackbar } = useBaseStore();
  const [name, setName] = useState("");
  const [type, setType] = useState("public");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState("draft");
  const [loader, setLoader] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [publishLoader, setPublishLoader] = useState(false);

  useEffect(() => {
    if (currentSurvey) {
      setName(currentSurvey.name);
      setType(currentSurvey.type);
      setStartDate(dayjs.utc(currentSurvey.startDate));
      setEndDate(dayjs.utc(currentSurvey.endDate));
      setStatus(currentSurvey.status);

      setInitialValues({
        name: currentSurvey.name,
        type: currentSurvey.type,
        startDate: dayjs.utc(currentSurvey.startDate),
        endDate: dayjs.utc(currentSurvey.endDate),
      });
    }
  }, [currentSurvey]);

  const hasChanges = useMemo(() => {
    return (
      JSON.stringify(initialValues) !==
      JSON.stringify({
        name,
        type,
        startDate: dayjs.utc(startDate),
        endDate: dayjs.utc(endDate),
      })
    );
  }, [initialValues, name, type, startDate, endDate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoader(true);

    try {
      const jwt = await getJwt();

      await API.put("surveys", `/surveys/${currentSurvey._id}`, {
        body: {
          name,
          type,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
        headers: { Authorization: `Bearer ${jwt}` },
      });

      const index = surveys.findIndex((s) => s._id === currentSurvey._id);

      setCurrentSurvey({
        ...currentSurvey,
        name,
        type,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      });

      setSurveys([
        ...surveys.slice(0, index),
        {
          ...currentSurvey,
          name,
          type,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
        ...surveys.slice(index + 1),
      ]);

      setSnackbar({
        open: true,
        type: "success",
        message: "Survey updated!",
      });
    } catch (e) {
      console.error(e.response);

      setSnackbar({
        open: true,
        type: "error",
        message: "Something went wrong",
      });
    }

    setLoader(false);
  };

  const handlePublish = async () => {
    setPublishLoader(true);

    try {
      const jwt = await getJwt();

      await API.put("surveys", `/surveys/status/${currentSurvey._id}`, {
        body: {
          status: "published",
        },
        headers: { Authorization: `Bearer ${jwt}` },
      });

      const index = surveys.findIndex((s) => s._id === currentSurvey._id);

      setCurrentSurvey({
        ...currentSurvey,
        name,
        type,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        status: "published",
      });

      setSurveys([
        ...surveys.slice(0, index),
        {
          ...currentSurvey,
          name,
          type,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          status: "published",
        },
        ...surveys.slice(index + 1),
      ]);

      setSnackbar({
        open: true,
        type: "success",
        message: "Survey was published!",
      });

      setDialog(false);
    } catch (e) {
      console.error(e.response);

      setSnackbar({
        open: true,
        type: "error",
        message: "Something went wrong",
      });
    }

    setPublishLoader(false);
  };

  return (
    <Stack
      sx={{
        flexGrow: 1,
        alignItems: "center",
      }}
      gap={4}
    >
      <Typography variant="h6">Edit Survey</Typography>

      {status === "draft" && (
        <Alert
          severity="warning"
          icon={
            <Stack sx={{ alignItems: "center", justifyContent: "center" }}>
              <Warning fontSize="inherit" />
            </Stack>
          }
        >
          <Stack direction="row" gap={2} sx={{ alignItems: "center" }}>
            <Typography>The survey is currently draft</Typography>

            <Button
              size="small"
              variant="contained"
              color="success"
              onClick={() => setDialog(true)}
            >
              Publish
            </Button>
          </Stack>
        </Alert>
      )}

      <Stack
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ width: "500px" }}
        gap={2}
      >
        <SurveyForm
          name={name}
          setName={setName}
          type={type}
          setType={setType}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />

        <LoadingButton
          loading={loader}
          variant="contained"
          type="submit"
          disabled={!name || !type || !startDate || !endDate || !hasChanges}
        >
          Edit Survey
        </LoadingButton>
      </Stack>

      <Dialog onClose={() => setDialog(false)} open={dialog}>
        <Stack sx={{ width: "600px", padding: 2 }}>
          <Stack
            sx={{
              flexGrow: 1,
              alignItems: "center",
            }}
            gap={2}
          >
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              Are you sure you want to publish the survey ?
            </Typography>

            <Stack direction="row" gap={1}>
              <Button
                size="small"
                onClick={() => setDialog(false)}
                color="error"
              >
                Cancel
              </Button>

              <LoadingButton
                loading={publishLoader}
                size="small"
                color="success"
                variant="contained"
                onClick={handlePublish}
              >
                Publish
              </LoadingButton>
            </Stack>
          </Stack>
        </Stack>
      </Dialog>
    </Stack>
  );
};

export default General;
