import { useNavigate, useParams } from "react-router-dom";
import { useMount } from "react-use";
import useEditorStore, { tabsWithSearch } from "../../../../../store/editor";
import { IconButton, Stack, TextField } from "@mui/material";
import Blocks from "./Blocks";
import Questions from "./Questions";
import Branches from "./Branches";
import Filters from "./filters/Filters";
import { Close } from "@mui/icons-material";
import useFilters from "../../../../../helpers/useFilters";
import { useState } from "react";
import DangerZone from "./DangerZone";
import { DANGER_ZONE_ENVIRONMENTS } from "../../../../../helpers/constants";

const Tabs = () => {
  const { id, tab } = useParams();
  const { editorSurvey, filters, setFilters } = useEditorStore();
  const navigate = useNavigate();
  const { filteredListContent } = useFilters();
  const [guardCheck, setGuardCheck] = useState(false);

  useMount(() => {
    const allowTab = editorSurvey[tab];

    const allowDangerZone =
      tab === "danger-zone" &&
      DANGER_ZONE_ENVIRONMENTS.includes(process.env.REACT_APP_STAGE);

    if (!allowTab && !allowDangerZone) navigate(`/surveys/${id}/editor`);

    setGuardCheck(true);
  });

  return (
    <Stack
      sx={{
        flexGrow: 1,
        alignItems: "center",
      }}
    >
      {guardCheck && (
        <>
          {tabsWithSearch.includes(tab) && (
            <Stack direction="row" gap={1} sx={{ mb: 2, alignItems: "center" }}>
              <TextField
                size="small"
                label={`Search ${
                  tab.charAt(0).toUpperCase() + tab.slice(1)
                } (By ID)`}
                variant="outlined"
                value={filters[tab]?.name}
                sx={{ width: "500px" }}
                onChange={(e) =>
                  setFilters({
                    tab,
                    filterKey: "name",
                    value: e.target.value,
                  })
                }
              />

              {filters[tab]?.name && (
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() =>
                    setFilters({
                      tab,
                      filterKey: "name",
                      value: "",
                    })
                  }
                >
                  <Close />
                </IconButton>
              )}

              <Filters />
            </Stack>
          )}

          {tab === "blocks" && <Blocks list={filteredListContent} />}

          {tab === "questions" && <Questions list={filteredListContent} />}

          {tab === "branches" && <Branches />}

          {tab === "danger-zone" && <DangerZone />}
        </>
      )}
    </Stack>
  );
};

export default Tabs;
