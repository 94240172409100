import {
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import useEditorStore from "../../../../../../store/editor";

const SelectFilter = ({
  filterKey,
  label,
  selectedLabel,
  itemArrayCallback,
}) => {
  const { tab } = useParams();
  const { filters: allFilters, editorSurvey, setFilters } = useEditorStore();

  const filters = useMemo(() => {
    return allFilters[tab];
  }, [tab, allFilters]);

  const allItems = useMemo(() => {
    const itemsArray = editorSurvey[tab].map(itemArrayCallback);
    const uniqueItemsSet = new Set(itemsArray);
    const uniqueItemsArray = Array.from(uniqueItemsSet);

    return uniqueItemsArray;
  }, [editorSurvey, tab, itemArrayCallback]);

  return (
    <Stack>
      <FormControl size="small" sx={{ mb: 1 }}>
        <InputLabel>{label}</InputLabel>

        <Select
          label={label}
          value={filters[filterKey]}
          onChange={(e) =>
            setFilters({
              tab,
              filterKey,
              value: e.target.value,
            })
          }
          renderValue={(selected) =>
            `${selected.length} ${selectedLabel}${
              selected.length === 1 ? "" : "s"
            } selected`
          }
          multiple
        >
          {allItems
            .filter((item) => item)
            .map((item) => (
              <MenuItem key={item} value={item}>
                <Checkbox checked={filters[filterKey].indexOf(item) > -1} />
                <ListItemText primary={item} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <Stack direction="row" sx={{ flexWrap: "wrap", gap: 0.5 }}>
        {filters[filterKey].map((value) => (
          <Chip
            key={value}
            label={value}
            onDelete={() =>
              setFilters({
                tab,
                filterKey,
                value: filters[filterKey].filter((t) => t !== value),
              })
            }
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default SelectFilter;
