import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";

const DecoratorsList = ({ fields }) => {
  const renderField = (field) => {
    if (field.type === "text") {
      const alias = field.alias ? (
        <Box
          component="span"
          sx={{ fontWeight: 500 }}
        >{`[${field.alias}]`}</Box>
      ) : (
        ""
      );

      return (
        <>
          {" "}
          {field.text} {alias}{" "}
        </>
      );
    }

    const decorator = (
      <Box component="span" sx={{ fontWeight: 500 }}>{`@${
        field.type
      } ${field.names.toString()}`}</Box>
    );

    if (field.text && field.type !== "text") {
      return (
        <>
          {field.text} {decorator}
        </>
      );
    }

    return decorator;
  };

  return (
    <List>
      {fields.map((field, index) => {
        return (
          <ListItem key={index}>
            <ListItemText
              primary={
                <Typography sx={{ textAlign: "center" }}>
                  {renderField(field)}
                </Typography>
              }
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default DecoratorsList;
