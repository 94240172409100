import {
  Alert,
  Container,
  Snackbar,
  Stack,
  Dialog,
  AlertTitle,
} from "@mui/material";
import { useMemo, useState } from "react";
import { Outlet } from "react-router";
import Navbar from "./components/Navbar";
import { useMount } from "react-use";
import { Auth } from "aws-amplify";
import { normalizeUser } from "./helpers/normalizeUser";
import useLanguageSupport from "./helpers/useLanguageSupport";
import DeleteSurvey from "./views/surveys/DeleteSurvey";
import LogoutConfirmation from "./components/LogoutConfirmation";
import UploadSurvey from "./views/surveys/edit-survey/editor/UploadSurvey";
import CreateTheme from "./views/themes/CreateTheme";
import DeleteTheme from "./views/themes/DeleteTheme";
import useAuthStore from "./store/auth";
import useBaseStore from "./store/base";

const App = () => {
  const [authInit, setAuthInit] = useState(false);
  const { setUser } = useAuthStore();
  const { snackbar, dialog, setDialog, setSnackbar } = useBaseStore();

  const monacoRegistered = useLanguageSupport();

  useMount(() => {
    (async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();

        if (user) setUser(normalizeUser(user));
      } catch (e) {
        console.log(e);
      }

      setAuthInit(true);
    })();
  });

  const init = useMemo(() => {
    return monacoRegistered && authInit;
  }, [monacoRegistered, authInit]);

  const handleSnackbarClose = () => {
    setSnackbar({
      open: false,
      type: "",
      message: "",
      title: "",
    });
  };

  const handleDialogClose = () => {
    setDialog({
      open: false,
      component: null,
      props: {},
    });
  };

  return (
    <Stack sx={{ height: "100vh" }}>
      <Navbar />

      <Container
        maxWidth="100%"
        sx={{
          flexGrow: 1,
          py: 2,
          display: "flex",
          flexDirection: " column",
          overflow: "auto",
        }}
      >
        {init ? <Outlet /> : <>Loading...</>}
      </Container>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.type ? snackbar.type : undefined}
          sx={{ width: "100%" }}
        >
          {snackbar.title && (
            <AlertTitle>
              {snackbar.title.charAt(0).toUpperCase() + snackbar.title.slice(1)}
            </AlertTitle>
          )}

          {snackbar.message}
        </Alert>
      </Snackbar>

      <Dialog onClose={handleDialogClose} open={dialog.open}>
        <Stack sx={{ width: "600px", padding: 2 }}>
          {dialog.component === "deleteSurvey" && (
            <DeleteSurvey {...dialog.props} onClose={handleDialogClose} />
          )}

          {dialog.component === "logout" && (
            <LogoutConfirmation onClose={handleDialogClose} />
          )}

          {dialog.component === "upload" && (
            <UploadSurvey onClose={handleDialogClose} />
          )}

          {dialog.component === "createTheme" && (
            <CreateTheme {...dialog.props} onClose={handleDialogClose} />
          )}

          {dialog.component === "deleteTheme" && (
            <DeleteTheme {...dialog.props} onClose={handleDialogClose} />
          )}
        </Stack>
      </Dialog>
    </Stack>
  );
};

export default App;
