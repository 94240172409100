import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import useSurveysStore from "../../../../../store/surveys";
import useBaseStore from "../../../../../store/base";
import { LoadingButton } from "@mui/lab";
import getJwt from "../../../../../helpers/getJwt";
import { API } from "aws-amplify";

const DangerZone = () => {
  const [dialog, setDialog] = useState(false);
  const currentSurvey = useSurveysStore((state) => state.currentSurvey);
  const setSnackbar = useBaseStore((state) => state.setSnackbar);
  const [loader, setLoader] = useState(false);

  const handleReset = async () => {
    setLoader(true);

    try {
      const jwt = await getJwt();

      await API.del("surveys", `/surveys/${currentSurvey._id}/responses`, {
        headers: { Authorization: `Bearer ${jwt}` },
      });

      setSnackbar({
        open: true,
        type: "success",
        message: "Survey was reset!",
      });

      setDialog(false);
    } catch (e) {
      console.error(e.response);

      setSnackbar({
        open: true,
        type: "error",
        message: "Something went wrong",
      });
    }

    setLoader(false);
  };

  return (
    <Stack
      sx={{
        flexGrow: 1,
        alignSelf: "stretch",
      }}
      gap={2}
    >
      <Alert severity="error">
        <AlertTitle>Warning</AlertTitle>
        Proceed with care when performing operations in the danger zone.
      </Alert>

      <Stack
        sx={{
          width: 600,
          border: "1px solid ",
          borderColor: "error.main",
          p: 2,
          borderRadius: "4px",
        }}
      >
        <Stack direction="row" sx={{ alignItems: "center" }}>
          <Stack>
            <Typography variant="h6" color="error">
              Survey Reset
            </Typography>

            <Typography color="textSecondary">
              Remove all survey responses to date
            </Typography>
          </Stack>

          <Button
            sx={{ ml: "auto" }}
            color="error"
            variant="outlined"
            onClick={() => setDialog(true)}
          >
            Reset
          </Button>
        </Stack>
      </Stack>

      <Dialog onClose={() => setDialog(false)} open={dialog}>
        <Stack sx={{ width: "600px", padding: 2 }}>
          <Stack
            sx={{
              flexGrow: 1,
              alignItems: "center",
            }}
            gap={2}
          >
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              Are you sure you want to reset survey responses for{" "}
              {currentSurvey.name} ?
            </Typography>

            <Stack direction="row" gap={1}>
              <Button
                size="small"
                onClick={() => setDialog(false)}
                color="primary"
              >
                Cancel
              </Button>

              <LoadingButton
                loading={loader}
                size="small"
                color="error"
                variant="contained"
                onClick={handleReset}
              >
                Reset
              </LoadingButton>
            </Stack>
          </Stack>
        </Stack>
      </Dialog>
    </Stack>
  );
};

export default DangerZone;
