import { Code } from "@mui/icons-material";
import { Alert, Button, Divider, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import Questions from "../tabs/Questions";
import useEditorStore, { useTabItem } from "../../../../../store/editor";
import useHasDecorators from "../../../../../helpers/useHasDecorators";
import Comments from "../../../../../components/Comments";

const Block = () => {
  const { itemId, tab } = useParams();
  const block = useTabItem(tab, itemId);
  const hasDecorators = useHasDecorators(block);
  const {
    editorSurvey: { questions: allQuestions },
  } = useEditorStore();

  const blockQuestions = useMemo(() => {
    return block.questions
      .map((block) => {
        return allQuestions.filter((question) =>
          block.items.includes(question.name)
        );
      })
      .flat()
      .map((q) => {
        return {
          name: q.name,
          type: q.type,
          title: q.title,
          required: q.required,
        };
      });
  }, [allQuestions, block]);

  return (
    <Stack
      sx={{
        flexGrow: 1,
        alignItems: "center",
        pt: 2,
      }}
      gap={2}
    >
      {block?.__metadata__?.rawBlockLogic && (
        <Divider sx={{ alignSelf: "stretch" }} />
      )}

      <Comments comments={block?.__metadata__?.rawBlockLogic} />

      {block?.__metadata__?.rawBlockLogic && (
        <Divider sx={{ alignSelf: "stretch" }} />
      )}

      {hasDecorators ? (
        <Alert
          severity="info"
          icon={<Code fontSize="inherit" />}
          action={
            <Button
              variant="outlined"
              color="primary"
              size="small"
              component={Link}
              to="code-editor"
            >
              Open Editor
            </Button>
          }
        >
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            This block contains decorators.
          </Typography>
        </Alert>
      ) : (
        <Alert severity="warning">
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            This block contains no decorators. All of its questions will always
            render.
          </Typography>
        </Alert>
      )}

      <Questions list={blockQuestions} from={itemId} />
    </Stack>
  );
};

export default Block;
