import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import getJwt from "../helpers/getJwt";
import { API } from "aws-amplify";

const useThemesStore = create()(
  immer((set, getState) => ({
    themes: null,
    themesFetched: null,
    currentTheme: null,
    initialCurrentTheme: null,
    currentPreview: "LOADING_PAGE",
    fetchThemes: async () => {
      if (getState().themesFetched) return getState().themes;

      const jwt = await getJwt();

      const response = await API.get("editor", "/themes", {
        headers: { Authorization: `Bearer ${jwt}` },
      });

      set((state) => {
        state.themes = response.themes;
        state.themesFetched = true;
      });

      return response.themes;
    },
    setThemes: (themes) =>
      set((state) => {
        state.themes = themes;
      }),
    setCurrentTheme: (currentTheme) =>
      set((state) => {
        state.currentTheme = {
          ...currentTheme,
          theme: {
            palette: {
              main: "#fd6983",
              hover: "#6a27e8",
              checkboxes: "#378aff",
              background: "#ffffff",
              explenatory: "#b0c0dA",
              outlines: "#b0c0dA",
              fonts: "#2b2a28",
              strips: "#f1f3f8",
            },
          },
        };
      }),
    setInitialCurrentTheme: (initialCurrentTheme) =>
      set((state) => {
        state.initialCurrentTheme = initialCurrentTheme;
      }),
    setCurrentPreview: (currentPreview) =>
      set((state) => {
        state.currentPreview = currentPreview;
      }),
    editCurrentTheme: ({ part, key, variant, value }) =>
      set((state) => {
        if (variant) {
          state.currentTheme[part][key][variant] = value;
        } else {
          state.currentTheme[part][key] = value;
        }
      }),
  }))
);

export default useThemesStore;
