import {
  createSearchParams,
  Link,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import KeyValueChip from "../../../../../components/KeyValueChip";
import { Code } from "@mui/icons-material";
import { useTabItem } from "../../../../../store/editor";
import DecoratorsList from "./DecoratorsList";
import Comments from "../../../../../components/Comments";
import useHasDecorators from "../../../../../helpers/useHasDecorators";

const Question = () => {
  const { itemId, tab } = useParams();
  const question = useTabItem(tab, itemId);
  const hasDecorators = useHasDecorators(question);
  const [searchParams] = useSearchParams();

  return (
    <Stack
      sx={{
        flexGrow: 1,
        alignItems: "center",
      }}
      gap={2}
    >
      <Divider sx={{ alignSelf: "stretch", mt: 1 }} />

      {/* CHIPS */}
      <Grid container spacing={1} justifyContent="center">
        <Grid item>
          <KeyValueChip ChipKey="Type" ChipValue={question.type} />
        </Grid>

        <Grid item>
          <KeyValueChip ChipKey="Title" ChipValue={question.title} />
        </Grid>

        {question.description && (
          <Grid item>
            <KeyValueChip
              ChipKey="Description"
              ChipValue={question.description}
            />
          </Grid>
        )}

        {question.content && (
          <Grid item>
            <KeyValueChip ChipKey="Content" ChipValue={question.content} />
          </Grid>
        )}

        <Grid item>
          <KeyValueChip
            ChipKey="Required"
            ChipValue={question.required ? "Yes" : "No"}
          />
        </Grid>

        <Grid item>
          <KeyValueChip
            ChipKey="Randomized Options"
            ChipValue={question.optionsRandomized ? "Yes" : "No"}
          />
        </Grid>

        {question.type.includes("GRID") && (
          <Grid item>
            <KeyValueChip
              ChipKey="Randomized Groups"
              ChipValue={question.groupsRandomized ? "Yes" : "No"}
            />
          </Grid>
        )}
      </Grid>

      <Comments comments={question?.__metadata__?.rawQuestionLogic} />

      {/* HAS DECORATORS ALERT */}
      {hasDecorators && <Divider sx={{ alignSelf: "stretch" }} />}

      {hasDecorators && (
        <Alert
          severity="info"
          icon={<Code fontSize="inherit" />}
          action={
            <Button
              variant="outlined"
              color="primary"
              size="small"
              component={Link}
              to={{
                pathname: "code-editor",
                search: searchParams.get("from")
                  ? createSearchParams({
                      from: searchParams.get("from"),
                    }).toString()
                  : "",
              }}
            >
              Open Editor
            </Button>
          }
        >
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            This question contains decorators.
          </Typography>
        </Alert>
      )}

      {(Boolean(question?.options?.length) ||
        Boolean(question?.groups?.length)) && (
        <Divider sx={{ alignSelf: "stretch" }} />
      )}

      <Stack direction="row" gap={4} sx={{ justifyContent: "center" }}>
        {/* OPTIONS */}
        {Boolean(question?.options?.length) && (
          <Box>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 600, textAlign: "center" }}
            >
              Options
            </Typography>

            <DecoratorsList fields={question.options} />
          </Box>
        )}

        {/* GROUPS */}
        {Boolean(question?.groups?.length) && (
          <Box>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 600, textAlign: "center" }}
            >
              Groups
            </Typography>

            <DecoratorsList fields={question.groups} />
          </Box>
        )}
      </Stack>

      {/* QUESTION LOGIC */}

      {Boolean(question.questionLogic.length) && (
        <Divider sx={{ alignSelf: "stretch" }} />
      )}

      {Boolean(question.questionLogic.length) && (
        <Box>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 600, textAlign: "center" }}
          >
            Question Logic
          </Typography>

          <DecoratorsList fields={question.questionLogic} />
        </Box>
      )}
    </Stack>
  );
};

export default Question;
