import { Button, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useSurveysStore from "../../../../store/surveys";
import { useMount } from "react-use";

const SheetId = () => {
  const { currentSurvey } = useSurveysStore();
  const [sheetId, setSheetId] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();

  useMount(() => {
    if (location?.state?.from !== "editor")
      navigate(`/surveys/${id}`, { replace: true });
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    localStorage.setItem(currentSurvey._id, sheetId);

    navigate(-1, { replace: true });
  };

  return (
    <Stack
      sx={{
        flexGrow: 1,
        alignItems: "center",
      }}
    >
      <Stack sx={{ width: "500px" }} component="form" onSubmit={handleSubmit}>
        <TextField
          label="Spreadsheet ID"
          variant="outlined"
          sx={{ mb: 2 }}
          value={sheetId}
          onChange={(e) => setSheetId(e.target.value)}
        />

        <Button variant="contained" type="submit" disabled={!sheetId}>
          Fetch Spreadsheet
        </Button>
      </Stack>
    </Stack>
  );
};

export default SheetId;
