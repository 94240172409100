import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { useEffect } from "react";

const SurveyForm = ({
  name,
  setName,
  type,
  setType,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  useEffect(() => {
    if (endDate && startDate && endDate.isBefore(startDate)) {
      setEndDate(null);
    }
  }, [startDate, endDate, setEndDate]);

  return (
    <>
      <TextField
        label="Name"
        variant="outlined"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <FormControl fullWidth>
        <InputLabel id="type-select-label">Type</InputLabel>

        <Select
          labelId="type-select-label"
          id="type-select"
          value={type}
          label="Type"
          onChange={(e) => setType(e.target.value)}
        >
          <MenuItem value="public">Public</MenuItem>
          <MenuItem value="private">Private</MenuItem>
          <MenuItem value="hybrid">Hybrid</MenuItem>
        </Select>
      </FormControl>

      <MobileDateTimePicker
        label="Start Date (UTC)"
        value={startDate}
        onChange={(newValue) => setStartDate(newValue.utc())}
        format="MMM DD YYYY | HH:mm"
        disableMaskedInput
        slots={{
          textField: (params) => (
            <TextField onKeyDown={(e) => e.preventDefault()} {...params} />
          ),
        }}
      />

      <MobileDateTimePicker
        label="End Date (UTC)"
        value={endDate}
        onChange={(newValue) => setEndDate(newValue.utc())}
        slots={{
          textField: (params) => (
            <TextField onKeyDown={(e) => e.preventDefault()} {...params} />
          ),
        }}
        minDateTime={startDate}
        format="MMM DD YYYY | HH:mm"
        disableMaskedInput
      />
    </>
  );
};

export default SurveyForm;
