import Editor from "@monaco-editor/react";
import { Stack, TextField } from "@mui/material";
import { cloneDeep } from "lodash";
import { useRef } from "react";
import useEditorStore from "../../../../../store/editor";
import ReactResizeDetector from "react-resize-detector";

const DecoratorsCode = ({
  tab,
  itemId,
  codePart,
  decoratorName,
  decoratorType,
  code,
}) => {
  const { editorSurvey, updateSurveyTab } = useEditorStore();
  const editorRef = useRef(null);

  const handleOnMount = (editor) => {
    // save the editor on mount for future use
    editorRef.current = editor;

    editor.updateOptions({
      fontSize: 16,
    });

    const model = editorRef.current.getModel();

    model.setEOL(0);
  };

  const handleOnChange = (e) => {
    if (codePart !== "limit") {
      // settimeout is a hack to get the most recent cursor position.
      setTimeout(() => {
        const model = editorRef.current.getModel(); //get model
        const position = editorRef.current.getPosition(); //get position

        const last_chars = model.getValueInRange({
          startLineNumber: position.lineNumber,
          startColumn: position.column - 2,
          endLineNumber: position.lineNumber,
          endColumn: position.column,
        }); //read line until the current cursor position

        if (last_chars === "()") {
          // if cursor is after the "()" move it one column back
          editorRef.current.setPosition({
            lineNumber: position.lineNumber,
            column: position.column - 1,
          });
        }
      }, 1);
    }

    // deep clone the survey tab. for example the whole questions | blocks etc.
    const surveyTab = cloneDeep(editorSurvey[tab]);

    // find the item based on itemId
    // we dont use the store selector because we are finding the item from the cloned survey tab in order to safely mutate it.
    const foundItem = surveyTab.find((item) => item.name === itemId);

    //find the code of the provided decorator
    let foundCodeObject = foundItem.logicDefinitions.find(
      (codeObject) => codeObject.name === decoratorName
    );

    //if foundCodeObject (meaning there is no code yet written)
    if (!foundCodeObject) {
      //construct a code object with empty logic...
      foundCodeObject = {
        name: decoratorName,
        type: decoratorType,
        logic: "",
      };

      // ... and empty input in case of import or variable
      if ((decoratorType === "import") | (decoratorType === "variable"))
        foundCodeObject["input"] = "";

      foundItem.logicDefinitions.push(foundCodeObject);
    }

    // if it is variable, always make limit = 1
    if (decoratorType === "variable") foundCodeObject["limit"] = 1;

    //update the code with the provided event
    foundCodeObject[codePart] = e;

    // if limit is empty string, make it undefined
    if (decoratorType === "import" && !e) {
      delete foundCodeObject.limit;
    }

    //finally mutate the survey in the redux.
    updateSurveyTab({ tab, content: surveyTab });
  };

  return (
    <>
      {codePart === "limit" ? (
        <TextField
          label="Limit"
          variant="outlined"
          type="number"
          onChange={(e) => handleOnChange(+e.target.value)}
          value={code}
        />
      ) : (
        <Stack sx={{ flexGrow: 1 }}>
          <ReactResizeDetector
            handleWidth
            handleHeight
            refreshMode="debounce"
            refreshRate={100}
            onResize={(width, height) => {
              editorRef.current?.layout({ width, height });
            }}
          />

          <Editor
            width="100%"
            height="200px"
            onMount={handleOnMount}
            theme="survey-lang-theme"
            language="survey-lang"
            value={code}
            onChange={handleOnChange}
            options={{
              automaticLayout: false,
            }}
          ></Editor>
        </Stack>
      )}
    </>
  );
};

export default DecoratorsCode;
