import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router";
import "./index.scss";
import router from "./router";
import { Amplify } from "aws-amplify";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import config from "./config";

dayjs.extend(utc);

Amplify.configure(config);

ReactDOM.createRoot(document.getElementById("root")).render(
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <RouterProvider router={router} />
  </LocalizationProvider>
);
