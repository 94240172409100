import { each, filter, flatten, get, map } from "lodash";

const main = (input) => {
  const pages = get(input, "pages", []);
  const questions = get(input, "questions", []);

  const pages_output = flatten(
    map(pages, (page) => ({
      name: `pages.${page.name}.content`,
      value: page.content,
    }))
  );
  const questions_output = flatten(
    map(questions, (question) => {
      const title = {
        name: `questions.${question.name}.title`,
        value: question.title,
      };
      const description = {
        name: `questions.${question.name}.description`,
        value: question.description,
      };
      const content = {
        name: `questions.${question.name}.content`,
        value: question.content,
      };

      const labels = map(get(question, "labels", []), (label) => ({
        name: `questions.${question.name}.labels.${label.name}.value`,
        value: label.value,
      }));

      const groups = map(get(question, "groups", []), (group) => ({
        name: `questions.${question.name}.groups.${group.name}.text`,
        value: group.text,
      }));
      const options = map(get(question, "options", []), (option) => ({
        name: `questions.${question.name}.options.${option.name}.text`,
        value: option.text,
      }));

      const features = map(get(question, "features", []), (feature) => ({
        name: `questions.${question.name}.features.${feature.name}.text`,
        value: feature.text,
      }));
      const levels = map(get(question, "levels", []), (level) => ({
        name: `questions.${question.name}.levels.${level.name}.text`,
        value: level.text,
      }));

      const multi_groups = map(get(question, "groups", []), (group) => ({
        name: `questions.${question.name}.groups.${group.name}.multi`,
        value: group.multi,
      }));
      const multi_options = map(get(question, "options", []), (option) => ({
        name: `questions.${question.name}.options.${option.name}.multi`,
        value: option.multi,
      }));

      each(multi_options, (o) => (o.value ? console.log(o) : false));

      return [
        title,
        description,
        content,
        flatten(labels),
        flatten(groups),
        flatten(options),
        flatten(features),
        flatten(levels),
        flatten(multi_groups),
        flatten(multi_options),
      ];
    })
  );

  return filter(flatten([...pages_output, ...questions_output]), "value");
};

export default main;
