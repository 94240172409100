import { ArrowBackIos } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const HeaderWithBackBtn = ({ redirect, header }) => {
  return (
    <Grid container>
      <Grid
        item
        xs={4}
        sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}
      >
        <IconButton size="small" component={Link} sx={{ mr: 2 }} to={redirect}>
          <ArrowBackIos fontSize="10px" />
        </IconButton>
      </Grid>

      <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
        {header}
      </Grid>

      <Grid item xs={4}></Grid>
    </Grid>
  );
};

export default HeaderWithBackBtn;
