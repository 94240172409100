import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import getJwt from "../helpers/getJwt";
import { API } from "aws-amplify";

const useSurveysStore = create()(
  immer((set, getState) => ({
    surveys: [],
    surveysFetched: false,
    currentSurvey: null,
    fetchSurveys: async () => {
      if (getState().surveysFetched) return getState().surveys;

      const jwt = await getJwt();

      const surveys = await API.get("surveys", "/surveys", {
        headers: { Authorization: `Bearer ${jwt}` },
      });

      set((state) => {
        state.surveys = surveys;
        state.surveysFetched = true;
      });

      return surveys;
    },
    setSurveys: (surveys) =>
      set((state) => {
        state.surveys = surveys;
      }),
    setCurrentSurvey: (currentSurvey) =>
      set((state) => {
        state.currentSurvey = currentSurvey;
      }),
  }))
);

export default useSurveysStore;
