import { Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

const Blocks = ({ list }) => {
  const navigate = useNavigate();

  return (
    <Stack
      sx={{
        flexGrow: 1,
        alignSelf: "stretch",
      }}
    >
      <DataGrid
        disableRowSelectionOnClick
        density="compact"
        disableColumnMenu
        getRowId={(row) => row.name}
        sx={{
          "& .MuiDataGrid-row": {
            cursor: "pointer",
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
            outline: "none !important",
          },
        }}
        rows={list}
        columns={[
          { field: "name", headerName: "ID", flex: 1 },
          { field: "status", headerName: "Status", flex: 1 },
          {
            field: "hasLogic",
            headerName: "Has Logic",
            flex: 1,
            renderCell: (params) => {
              return <Typography>{params.value ? "YES" : "NO"}</Typography>;
            },
          },
          {
            field: "implementedLogic",
            headerName: "Implemented Logic",
            flex: 1,
            renderCell: (params) => {
              return (
                <Typography>
                  {params.row.hasLogic ? (params.value ? "YES" : "NO") : "-"}
                </Typography>
              );
            },
          },
        ]}
        onRowClick={(row) => {
          navigate(row.row.name);
        }}
      />
    </Stack>
  );
};

export default Blocks;
