import { Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import useSurveysStore from "../../../../../store/surveys";
import useEditorStore from "../../../../../store/editor";

const Branches = () => {
  const { currentSurvey } = useSurveysStore();
  const { editorSurvey } = useEditorStore();

  return (
    <Stack
      sx={{
        flexGrow: 1,
        alignSelf: "stretch",
      }}
    >
      <DataGrid
        disableRowSelectionOnClick
        density="compact"
        disableColumnMenu
        hideFooter
        getRowId={(row) => `${row.name}-${row.version}`}
        sx={{
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "transparent",
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
            outline: "none !important",
          },
        }}
        rows={editorSurvey.branches}
        columns={[
          { field: "name", headerName: "Name" },
          { field: "version", headerName: "Version" },
          {
            field: "link",
            headerName: "Survey Tool Link",
            flex: 1,
            renderCell: (params) => {
              return (
                <Typography
                  component="a"
                  href={`${process.env.REACT_APP_SURVEY_TOOL_BASE_URL}/name/${currentSurvey.name}/branch/${params.row.name}`}
                  target="_blank"
                  sx={{ color: "primary.main" }}
                >
                  {process.env.REACT_APP_SURVEY_TOOL_BASE_URL}/name/
                  {currentSurvey.name}/branch/{params.row.name}
                </Typography>
              );
            },
          },
        ]}
      />
    </Stack>
  );
};

export default Branches;
