import { useMount } from "react-use";
import useSurveysStore from "../../../../store/surveys";
import useBaseStore from "../../../../store/base";
import {
  Link,
  Outlet,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useCallback, useState } from "react";
import { API } from "aws-amplify";
import useEditorStore from "../../../../store/editor";
import getJwt from "../../../../helpers/getJwt";
import importer from "@slashdata-ltd/survey-tool-compiler-xls/importer";
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { CloudSync, CloudUpload, Warning } from "@mui/icons-material";
import { DANGER_ZONE_ENVIRONMENTS } from "../../../../helpers/constants";

const Editor = () => {
  const { currentSurvey } = useSurveysStore();
  const {
    editorSurvey,
    editorSurveyFetched,
    setEditorSurvey,
    sheetId,
    setSheetId,
    setEditorSurveyFetched,
    setInitialEditorSurvey,
    setImportErrorMsg,
    importErrorMsg,
  } = useEditorStore();
  const { setDialog } = useBaseStore();
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const { tab } = useParams();
  const TabItemMatch = useMatch("/surveys/:id/editor/:tab/:itemId");
  const CodeEditorMatch = useMatch(
    "/surveys/:id/editor/:tab/:itemId/code-editor"
  );

  const fetchEditorSurvey = useCallback(
    async ({ sheetId, refresh = false }) => {
      try {
        if (editorSurveyFetched && !refresh) return;

        const jwt = await getJwt();

        const response = await API.post("editor", "/converter/importer", {
          body: {
            sheetId,
          },
          headers: { Authorization: `Bearer ${jwt}` },
        });

        const survey = {
          ...importer(response.allSheets),
          title: response.title,
        };

        survey.questions = survey.questions.filter((q) => {
          return (
            q?.__metadata__?.status !== "Killed" &&
            q?.__metadata__?.status !== "Inactive"
          );
        });

        survey.blocks = survey.blocks.filter((b) => {
          return (
            b?.__metadata__?.status !== "Killed" &&
            b?.__metadata__?.status !== "Inactive"
          );
        });

        setEditorSurvey(survey);

        setInitialEditorSurvey(survey);

        setSheetId(sheetId);

        setEditorSurveyFetched(true);

        setImportErrorMsg(null);
      } catch (e) {
        console.log(e);

        setImportErrorMsg(
          e.response?.data?.message
            ? e.response?.data?.message
            : "Something went wrong."
        );
      }
    },
    [
      editorSurveyFetched,
      setEditorSurvey,
      setSheetId,
      setEditorSurveyFetched,
      setImportErrorMsg,
      setInitialEditorSurvey,
    ]
  );

  useMount(() => {
    const LSsheetId = localStorage.getItem(currentSurvey._id);

    if (!LSsheetId) return navigate("sheetId", { state: { from: "editor" } });

    (async () => {
      await fetchEditorSurvey({ sheetId: LSsheetId });

      setLoader(false);
    })();
  });

  const handleRefresh = async () => {
    setLoader(true);

    await fetchEditorSurvey({ sheetId, refresh: true });

    setLoader(false);
  };

  return (
    <Stack
      sx={{
        flexGrow: 1,
      }}
    >
      {loader ? (
        <CircularProgress
          sx={{ alignSelf: "center", my: "auto" }}
          size="100px"
          thickness={2}
        />
      ) : importErrorMsg ? (
        <Stack sx={{ alignSelf: "center", width: "500px" }} gap={2}>
          <Alert severity="error">
            <AlertTitle>Spreadsheet Error</AlertTitle>
            {importErrorMsg}
          </Alert>

          <Button
            sx={{ alignSelf: "center" }}
            component={Link}
            to="sheetId"
            state={{ from: "editor" }}
          >
            Change Spreadsheet id
          </Button>
        </Stack>
      ) : (
        <>
          <Stack direction="row" gap={1} justifyContent="center">
            <Stack direction="row" gap={1} alignItems="center">
              <Typography variant="body1" sx={{ fontWeight: 600 }}>
                Spreadsheet Title:
              </Typography>

              <Typography variant="body1" component="span">
                {editorSurvey?.title}
              </Typography>
            </Stack>

            <Tooltip
              title="Refresh spreadsheet"
              onClick={() => handleRefresh()}
            >
              <IconButton color="secondary" size="small">
                <CloudSync />
              </IconButton>
            </Tooltip>

            <Tooltip
              title="Deploy Survey"
              onClick={() =>
                setDialog({
                  open: true,
                  component: "upload",
                  props: null,
                })
              }
            >
              <IconButton color="secondary">
                <CloudUpload />
              </IconButton>
            </Tooltip>
          </Stack>

          <Divider sx={{ my: 1 }} />

          {!TabItemMatch && !CodeEditorMatch && (
            <Tabs
              sx={{ mb: 2, alignSelf: "center" }}
              value={tab ? tab : "questions"}
            >
              {DANGER_ZONE_ENVIRONMENTS.includes(
                process.env.REACT_APP_STAGE
              ) && (
                <Tab
                  label="Danger Zone"
                  value="danger-zone"
                  component={Link}
                  to="danger-zone"
                  icon={<Warning />}
                  iconPosition="start"
                  sx={{ minHeight: "auto" }}
                />
              )}

              <Tab
                label="Branches"
                value="branches"
                component={Link}
                to="branches"
              />

              <Tab label="Blocks" value="blocks" component={Link} to="blocks" />

              <Tab
                label="Questions"
                value="questions"
                component={Link}
                to="questions"
              />
            </Tabs>
          )}

          <Outlet />
        </>
      )}
    </Stack>
  );
};

export default Editor;
