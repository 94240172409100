import { Stack, TextField, Typography, Alert } from "@mui/material";
import { useState } from "react";
import { Auth } from "aws-amplify";
import { useLocation, useNavigate } from "react-router-dom";
import { normalizeUser } from "../helpers/normalizeUser";
import useAuthStore from "../store/auth";
import { LoadingButton } from "@mui/lab";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const { setUser } = useAuthStore();
  const location = useLocation();
  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();

    setInvalidCredentials(false);
    setLoader(true);

    try {
      const user = await Auth.signIn(email, password);

      setUser(normalizeUser(user));

      let from = location.state?.from?.pathname || "/";
      navigate(from, { replace: true });
    } catch (e) {
      console.log(e);

      switch (e.code) {
        case "NotAuthorizedException":
        case "UserNotFoundException":
          setInvalidCredentials(true);
          break;
        default:
          console.log("default");
      }
    }

    setLoader(false);
  };

  return (
    <Stack
      sx={{
        flexGrow: 1,
        alignItems: "center",
      }}
    >
      <Typography
        variant="h3"
        color="primary"
        sx={{
          fontFamily: "'Paytone One', sans- serif",
          mb: "20px",
          fontSize: "36px",
        }}
      >
        Sign In
      </Typography>

      <Stack
        component="form"
        onSubmit={handleSignIn}
        noValidate
        gap={2}
        sx={{ width: "450px" }}
      >
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />

        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />

        {invalidCredentials && (
          <Alert severity="error">Invalid Credentials</Alert>
        )}

        <LoadingButton
          loading={loader}
          variant="contained"
          type="submit"
          disabled={!email || !password}
        >
          Sign in
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default Login;
