import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

const useAuthStore = create()(
  immer((set) => ({
    user: null,
    setUser: (user) =>
      set((state) => {
        state.user = user;
      }),
  }))
);

export default useAuthStore;
