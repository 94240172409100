import { Box, Breadcrumbs, Stack, Typography } from "@mui/material";
import {
  createSearchParams,
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import HeaderWithBackBtn from "../../../../../components/HeaderWithBackBtn";
import useHasDecorators from "../../../../../helpers/useHasDecorators";
import Decorators from "./Decorators";
import { useState } from "react";
import { useMount } from "react-use";
import useEditorStore, { useTabItem } from "../../../../../store/editor";
import useBaseStore from "../../../../../store/base";
import { LoadingButton } from "@mui/lab";
import getJwt from "../../../../../helpers/getJwt";
import { API } from "aws-amplify";

const CodeEditor = () => {
  const { id, tab, itemId } = useParams();
  const tabItem = useTabItem(tab, itemId);
  const hasDecorators = useHasDecorators(tabItem);
  const navigate = useNavigate();
  const { editorSurvey, worker, sheetId, setInitialEditorSurvey } =
    useEditorStore();
  const [searchParams] = useSearchParams();
  const [compileLoader, setCompileLoader] = useState();
  const [saveLoader, setSaveLoader] = useState();
  const { setSnackbar } = useBaseStore();
  const [guardCheck, setGuardCheck] = useState(false);

  useMount(() => {
    if (!hasDecorators) navigate(`/surveys/${id}/editor/${tab}/${itemId}`);

    setGuardCheck(true);
  });

  const onCompile = async () => {
    setCompileLoader(true);

    try {
      await worker.compile(editorSurvey);

      setSnackbar({
        open: true,
        type: "success",
        message: "Compiled successfully",
      });
    } catch (e) {
      console.error(e);
    }

    setCompileLoader(false);
  };

  const onSave = async () => {
    setSaveLoader(true);

    try {
      const jwt = await getJwt();

      const questions = editorSurvey.questions.map((q) => {
        let validLogicDefinitions = [];

        const optionLogic = q.options.filter(
          (opt) => opt.type === "display" || opt.type === "import"
        );

        const groupLogic = q.groups.filter(
          (grp) => grp.type === "display" || grp.type === "import"
        );

        const allLogic = [...q.questionLogic, ...optionLogic, ...groupLogic];

        validLogicDefinitions = q.logicDefinitions
          .filter((ld) => allLogic.find((ql) => ql.names.includes(ld.name)))
          .map((ld) => ({
            ...ld,
            type: allLogic.find((ql) => ql.names.includes(ld.name)).type,
          }));

        console.log(validLogicDefinitions);

        return {
          id: q.name,
          logicDefinitions: JSON.stringify(validLogicDefinitions, null, 2),
        };
      });

      const blocks = editorSurvey.blocks.map((b) => {
        let validLogicDefinitions = [];

        validLogicDefinitions = b.logicDefinitions
          .filter((ld) => b.blockLogic.find((ql) => ql.names.includes(ld.name)))
          .map((ld) => ({
            ...ld,
            type: b.blockLogic.find((ql) => ql.names.includes(ld.name)).type,
          }));

        return {
          id: b.name,
          logicDefinitions: JSON.stringify(validLogicDefinitions),
        };
      });

      await API.post("editor", "/editor/writer", {
        body: {
          sheetId,
          questions,
          blocks,
        },
        headers: { Authorization: `Bearer ${jwt}` },
      });

      setInitialEditorSurvey(editorSurvey);

      setSnackbar({
        open: true,
        type: "success",
        message: "Code saved successfully!",
      });
    } catch (e) {
      console.log(e);

      setSnackbar({
        open: true,
        type: "error",
        message: "Error while saving code",
      });
    }

    setSaveLoader(false);
  };

  return (
    <Stack
      sx={{
        flexGrow: 1,
      }}
    >
      {guardCheck && (
        <>
          <Box sx={{ py: 1 }}>
            <HeaderWithBackBtn
              header={
                <Breadcrumbs>
                  <Typography
                    component={Link}
                    to={`/surveys/${id}/editor/${tab}`}
                    sx={{
                      color: "primary.main",
                      fontWeight: 500,
                      textDecoration: "none",
                      "&:hover": {
                        color: "primary.light",
                        textDecoration: "underline",
                      },
                    }}
                  >
                    {tab.charAt(0).toUpperCase() + tab.slice(1)}
                  </Typography>

                  <Typography
                    component={Link}
                    to={`/surveys/${id}/editor/${tab}/${tabItem.name}`}
                    sx={{
                      color: "primary.main",
                      fontWeight: 500,
                      textDecoration: "none",
                      "&:hover": {
                        color: "primary.light",
                        textDecoration: "underline",
                      },
                    }}
                  >
                    {tabItem.name}
                  </Typography>

                  <Typography color="text.primary" sx={{ fontWeight: 500 }}>
                    Code Editor
                  </Typography>
                </Breadcrumbs>
              }
              redirect={{
                pathname: `/surveys/${id}/editor/${tab}/${tabItem.name}`,
                search: searchParams.get("from")
                  ? createSearchParams({
                      from: searchParams.get("from"),
                    }).toString()
                  : "",
              }}
            />
          </Box>

          <Decorators />

          <Stack direction="row" sx={{ alignSelf: "center" }} gap={2}>
            <LoadingButton
              loading={compileLoader}
              size="large"
              variant="contained"
              onClick={onCompile}
            >
              Compile
            </LoadingButton>

            <LoadingButton
              loading={saveLoader}
              size="large"
              color="secondary"
              variant="contained"
              onClick={onSave}
            >
              Save Code
            </LoadingButton>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default CodeEditor;
