import { Close } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useParams } from "react-router-dom";
import { useMemo } from "react";
import useEditorStore from "../../../../../../store/editor";

const BooleanFilter = ({ filterKey, label }) => {
  const { tab } = useParams();
  const { filters: allFilters, setFilters } = useEditorStore();

  const filters = useMemo(() => {
    return allFilters[tab][filterKey];
  }, [tab, allFilters, filterKey]);

  return (
    <Stack direction="row" gap={1}>
      <FormControl size="small" sx={{ flexGrow: 1 }}>
        <InputLabel>{label}</InputLabel>

        <Select
          label={label}
          value={filters}
          onChange={(e) =>
            setFilters({
              tab,
              filterKey,
              value: e.target.value,
            })
          }
        >
          <MenuItem value="YES">YES</MenuItem>
          <MenuItem value="NO">NO</MenuItem>
        </Select>
      </FormControl>

      {filters && (
        <IconButton
          size="small"
          color="primary"
          onClick={() =>
            setFilters({
              tab,
              filterKey,
              value: "",
            })
          }
        >
          <Close />
        </IconButton>
      )}
    </Stack>
  );
};

export default BooleanFilter;
