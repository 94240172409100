import { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { FilterList } from "@mui/icons-material";
import { Badge, Box, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import SelectFilter from "./SelectFilter";
import BooleanFilter from "./BooleanFilter";
import useEditorStore from "../../../../../../store/editor";
import useFilters from "../../../../../../helpers/useFilters";

const Filters = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { tab } = useParams();
  const { activeFilterCount } = useFilters();
  const { clearFilters } = useEditorStore();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Badge badgeContent={activeFilterCount} color="primary">
        <Button
          onClick={handleClick}
          size="small"
          startIcon={<FilterList />}
          variant="outlined"
        >
          Filters
        </Button>
      </Badge>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {activeFilterCount ? (
          <Button
            size="small"
            variant="text"
            sx={{ my: 1, ml: 1 }}
            onClick={() => {
              clearFilters(tab);
            }}
          >
            Clear All
          </Button>
        ) : (
          <Box></Box>
        )}

        <Stack sx={{ width: 300, padding: 1 }} gap={2}>
          {(tab === "questions" || tab === "blocks") && (
            // SECTORS
            <SelectFilter
              filterKey="status"
              label="Status"
              selectedLabel="status"
              itemArrayCallback={(item) => item?.__metadata__?.status}
            />
          )}

          {tab === "questions" && (
            // SECTORS
            <SelectFilter
              filterKey="sector"
              label="Sectors"
              selectedLabel="sector"
              itemArrayCallback={(item) => item?.__metadata__?.sector}
            />
          )}

          {tab === "questions" && (
            // TYPES
            <SelectFilter
              filterKey="type"
              label="Types"
              selectedLabel="type"
              itemArrayCallback={(item) => item.type}
            />
          )}

          {tab === "questions" && (
            // OWNERS
            <SelectFilter
              filterKey="owner"
              label="Owners"
              selectedLabel="owner"
              itemArrayCallback={(item) => item?.__metadata__?.owner}
            />
          )}

          {tab === "questions" && (
            // REQUIRED
            <BooleanFilter filterKey="required" label="Required" />
          )}

          {(tab === "questions" || tab === "blocks") && (
            // HAS LOGIC
            <BooleanFilter filterKey="hasLogic" label="Has Logic" />
          )}

          {(tab === "questions" || tab === "blocks") && (
            // IMPLEMENTED LOGIC
            <BooleanFilter
              filterKey="implementedLogic"
              label="Implemented Logic"
            />
          )}
        </Stack>
      </Menu>
    </>
  );
};

export default Filters;
