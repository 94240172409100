import { Alert, AlertTitle, Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useMount } from "react-use";
import useAuthStore from "../store/auth";

const Unauthorized = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setUser, user } = useAuthStore();

  useMount(() => {
    if (location?.state?.from !== "guard") return navigate("/");

    setUser({
      ...user,
      unauthorized: true,
    });
  });

  return (
    <Stack
      sx={{
        flexGrow: 1,
        alignItems: "center",
      }}
    >
      <Alert severity="warning">
        <AlertTitle>Unauthorized</AlertTitle>
        This account does not have admin privileges. Once admin privileges are
        applied, please logout and login again.
      </Alert>
    </Stack>
  );
};

export default Unauthorized;
