import { CloudUpload } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, AlertTitle, Button, Dialog, Stack } from "@mui/material";
import { useState } from "react";
import useSurveysStore from "../../../../store/surveys";
import { API } from "aws-amplify";
import { map } from "lodash";
import extractor from "../../../../helpers/extractor";
import getJwt from "../../../../helpers/getJwt";
import useBaseStore from "../../../../store/base";

const UploadUntranslatedContent = () => {
  const [dialog, setDialog] = useState(false);
  const [loader, setLoader] = useState(false);
  const currentSurvey = useSurveysStore((state) => state.currentSurvey);
  const setSnackbar = useBaseStore((state) => state.setSnackbar);

  const handleUpload = async () => {
    setLoader(true);

    try {
      const { pages, questions } = await API.get(
        "surveys",
        `/surveys/name/${currentSurvey.name}/exporter`
      ); // get survey data

      const result = map(extractor({ pages, questions }), (item) => ({
        content: item.value,
      }));
      // map the result to the appropriate format.
      // only pages and questions are relevant for translations

      const jwt = await getJwt();

      await API.put("translations", "/importer", {
        body: {
          locale: "en",
          source: currentSurvey.name,
          values: result,
        },
        headers: { Authorization: `Bearer ${jwt}` },
      }); // upload the content in the translations DB

      setSnackbar({
        open: true,
        type: "success",
        message:
          "Untranslated content was successfully uploaded to translations database!",
      });
      setDialog(false);
    } catch (e) {
      console.error(e);

      setSnackbar({
        open: true,
        type: "error",
        message: "Error while uploading untranslated content",
      });
    }

    setLoader(false);
  };

  return (
    <Stack sx={{ alignItems: "center" }}>
      <Button variant="outlined" onClick={() => setDialog(true)}>
        1. Upload Content to Translations DB
      </Button>

      <Dialog onClose={() => setDialog(false)} open={dialog}>
        <Stack sx={{ width: "600px", padding: 2 }} gap={2}>
          <Alert severity="warning">
            <AlertTitle>Warning</AlertTitle>
            Make sure the survey's content is <strong>frozen</strong>
          </Alert>

          <Alert severity="warning">
            <AlertTitle>Warning</AlertTitle>
            <strong>Deploy</strong> latest content from the editor tab is{" "}
            <strong>required</strong>
          </Alert>

          <LoadingButton
            loading={loader}
            variant="outlined"
            onClick={handleUpload}
            startIcon={<CloudUpload />}
          >
            Upload
          </LoadingButton>
        </Stack>
      </Dialog>
    </Stack>
  );
};

export default UploadUntranslatedContent;
