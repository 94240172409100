import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

const useBaseStore = create()(
  immer((set) => ({
    snackbar: {
      open: false,
      type: "",
      message: "",
    },
    dialog: {
      open: false,
      component: null,
      props: {},
    },
    setRouteLoader: (routeLoader) =>
      set((state) => {
        state.routeLoader = routeLoader;
      }),
    setSnackbar: (snackbar) =>
      set((state) => {
        state.snackbar = snackbar;
      }),
    setDialog: (dialog) =>
      set((state) => {
        state.dialog = dialog;
      }),
  }))
);

export default useBaseStore;
