import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import KeyValueChip from "../../../../../components/KeyValueChip";
import DecoratorsCode from "./DecoratorsCode";
import { useTabItem } from "../../../../../store/editor";
import Comments from "../../../../../components/Comments";

const Decorators = () => {
  const { tab, itemId } = useParams();
  const tabItem = useTabItem(tab, itemId);

  const decoratorList = useMemo(() => {
    // part is options | groups | question logic | block logic
    // extracts the decorators
    const extractDecorators = (part) => {
      if (!part) return [];

      return part.filter((p) => p.type !== "text");
    };

    const extracted = {
      options: { title: "Options", list: extractDecorators(tabItem.options) },
      groups: { title: "Groups", list: extractDecorators(tabItem.groups) },
      questionLogic: {
        title: "Question Logic",
        list: extractDecorators(tabItem.questionLogic),
      },
      blockLogic: {
        title: "Block Logic",
        list: extractDecorators(tabItem.blockLogic),
      },
    };

    return (
      Object.keys(extracted)
        //filter all parts that have items in list
        .filter((key) => extracted[key].list.length)
        // map each part to have the desired format
        .map((key) => {
          const list = [];

          //for each list item
          extracted[key].list.forEach((item) => {
            // for each name(decorator) of item that does not exist in the list (remove duplicates)
            item.names
              .filter((name) => !list.find((item) => item.name === name))
              .forEach((name) => {
                //find the code of this decorator in the logicDefinitions for initialization
                const code = tabItem.logicDefinitions.find(
                  (ld) => ld.name === name
                );

                //extract input and logic
                const input = code ? code.input : undefined;
                const logic = code ? code.logic : undefined;
                const limit = code?.limit || "";

                //push new list item
                list.push({
                  type: item.type,
                  name,
                  input,
                  logic,
                  limit,
                });
              });
          });

          //replace the list prop of "extracted" with the newly created list
          //it has one item for each name instead of names[]
          //it has existing code prepopulated.
          return { ...extracted[key], list };
        })
    );
  }, [tabItem]);

  const commentsContent = useMemo(() => {
    if (tab === "blocks") return tabItem?.__metadata__?.rawBlockLogic;

    if (tab === "questions") return tabItem?.__metadata__?.rawQuestionLogic;

    return "";
  }, [tab, tabItem]);

  return (
    <Stack
      sx={{
        flexGrow: 1,
        alignSelf: "stretch",
      }}
      gap={2}
    >
      <Divider sx={{ alignSelf: "stretch", mt: 1 }} />

      {commentsContent && (
        <Box sx={{ alignSelf: "center" }}>
          <Comments comments={commentsContent} />
        </Box>
      )}

      {decoratorList.map((part) => {
        return (
          <Stack sx={{ mb: 2 }} key={part.title}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 600 }}>
              {part.title}
            </Typography>

            {part.list.map((item, index) => {
              return (
                <Accordion key={`${index}${item.type}${item.name}`}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography
                      sx={{ fontWeight: 500 }}
                    >{`@${item.type} ${item.name}`}</Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Grid container spacing={1} sx={{ mb: 2 }}>
                      <Grid item>
                        <KeyValueChip ChipKey="Type" ChipValue={item.type} />
                      </Grid>

                      <Grid item>
                        <KeyValueChip ChipKey="Title" ChipValue={item.name} />
                      </Grid>
                    </Grid>

                    <Stack direction="row" spacing={1}>
                      {(item.type === "import" ||
                        item.type === "variable" ||
                        item.type === "unique") && (
                        <Stack sx={{ flex: 1 }}>
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: 500, mb: 1 }}
                          >
                            Input
                          </Typography>

                          <DecoratorsCode
                            tab={tab}
                            itemId={itemId}
                            codePart="input"
                            decoratorName={item.name}
                            decoratorType={item.type}
                            code={item.input}
                          />
                        </Stack>
                      )}

                      {item.type !== "unique" && (
                        <Stack sx={{ flex: 1 }}>
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: 500, mb: 1 }}
                          >
                            Logic
                          </Typography>

                          <DecoratorsCode
                            tab={tab}
                            itemId={itemId}
                            codePart="logic"
                            decoratorName={item.name}
                            decoratorType={item.type}
                            code={item.logic}
                          />
                        </Stack>
                      )}
                    </Stack>

                    {item.type === "import" && (
                      <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                        <DecoratorsCode
                          tab={tab}
                          itemId={itemId}
                          codePart="limit"
                          decoratorName={item.name}
                          decoratorType={item.type}
                          code={item.limit}
                        />
                      </Stack>
                    )}
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default Decorators;
