import { Navigate, useLocation } from "react-router-dom";
import useAuthStore from "../store/auth";

const AuthGuard = ({ children, requiresAuth, allowedRoles }) => {
  const { user } = useAuthStore();
  const roles = user?.groups || [];
  let location = useLocation();

  if (requiresAuth) {
    if (!user) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    let allowed = roles.some((role) => allowedRoles.includes(role));

    if (!allowed)
      return <Navigate to="/unauthorized" state={{ from: "guard" }} replace />;
  } else {
    if (user) {
      return <Navigate to="/" replace />;
    }
  }

  return children;
};

export default AuthGuard;
