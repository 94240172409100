import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { API } from "aws-amplify";
import { useState } from "react";
import useSurveysStore from "../../../../store/surveys";
import getJwt from "../../../../helpers/getJwt";
import useBaseStore from "../../../../store/base";
import { AVAILABLE_LOCALES } from "../../../../helpers/constants";

const DownloadUntranslatedContent = () => {
  const currentSurvey = useSurveysStore((state) => state.currentSurvey);
  const setSnackbar = useBaseStore((state) => state.setSnackbar);

  const [locale, setLocale] = useState("es");
  const [loader, setLoader] = useState(false);

  const handleDownload = async () => {
    setLoader(true);

    try {
      const jwt = await getJwt();

      const res = await API.get(
        "translations",
        `/importer?locale=${locale}&source=${currentSurvey.name}`,
        {
          headers: { Authorization: `Bearer ${jwt}` },
        }
      ); // downloads the content of the survey that translations do not exist for the selected locale

      const blob = new Blob([JSON.stringify(res)], {
        type: "application/json",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${currentSurvey.name}_${locale}_to_be_translated.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      setSnackbar({
        open: true,
        type: "success",
        message: "Untranslated content was successfully downloaded",
      });
    } catch (e) {
      console.error(e);

      setSnackbar({
        open: true,
        type: "error",
        message: "Error while downloading untranslated content",
      });
    }

    setLoader(false);
  };

  return (
    <Stack sx={{ alignItems: "center" }}>
      <Stack direction="row" sx={{ justifyContent: "center" }} gap={2}>
        <FormControl size="small">
          <InputLabel id="demo-simple-select-label">Locale</InputLabel>

          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={locale}
            label="Locale"
            onChange={(event) => setLocale(event.target.value)}
          >
            {AVAILABLE_LOCALES.map((locale) => (
              <MenuItem key={locale.id} value={locale.id}>
                {locale.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <LoadingButton
          loading={loader}
          variant="outlined"
          onClick={handleDownload}
        >
          2. Download content
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default DownloadUntranslatedContent;
