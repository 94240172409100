import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import getJwt from "../../../../helpers/getJwt";
import { API } from "aws-amplify";
import useSurveysStore from "../../../../store/surveys";
import useBaseStore from "../../../../store/base";
import { useMemo, useState } from "react";
import { AVAILABLE_LOCALES } from "../../../../helpers/constants";
import { LoadingButton } from "@mui/lab";

const GenerateAllContent = () => {
  const currentSurvey = useSurveysStore((state) => state.currentSurvey);
  const setSnackbar = useBaseStore((state) => state.setSnackbar);

  const [locale, setLocale] = useState("es");
  const [loader, setLoader] = useState(false);
  const [url, setUrl] = useState(null);

  const localeValue = useMemo(() => {
    return AVAILABLE_LOCALES.find((l) => l.id === locale)?.value;
  }, [locale]);

  const handleDownload = async () => {
    setLoader(true);

    try {
      const jwt = await getJwt();

      const { url } = await API.post("editor", "/translations/downloader", {
        body: {
          survey: currentSurvey.name,
          locale: locale,
        },
        headers: { Authorization: `Bearer ${jwt}` },
      });

      setUrl(url);
    } catch (e) {
      console.error(e);

      setSnackbar({
        open: true,
        type: "error",
        message: "Error while generating spreadsheet",
      });
    }

    setLoader(false);
  };

  return (
    <Stack sx={{ alignItems: "center" }} gap={2}>
      <Stack direction="row" sx={{ justifyContent: "center" }} gap={2}>
        <FormControl size="small">
          <InputLabel id="demo-simple-select-label">Locale</InputLabel>

          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={locale}
            label="Locale"
            onChange={(event) => setLocale(event.target.value)}
          >
            {AVAILABLE_LOCALES.map((locale) => (
              <MenuItem key={locale.id} value={locale.id}>
                {locale.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <LoadingButton
          loading={loader}
          variant="outlined"
          onClick={handleDownload}
        >
          4. Compare content in spreadsheet
        </LoadingButton>
      </Stack>

      {url && (
        <Alert severity="success">
          You can compare original content with <strong>{localeValue}</strong>{" "}
          translations{" "}
          <Typography component="a" href={url} variant="body2" target="_blank">
            here
          </Typography>
          .
        </Alert>
      )}
    </Stack>
  );
};

export default GenerateAllContent;
