import { useMemo } from "react";

const useHasDecorators = (tabItem) => {
  const hasDecorators = useMemo(() => {
    if (!tabItem) return false;

    return [
      ...(tabItem.groups ? tabItem.groups : []),
      ...(tabItem.options ? tabItem.options : []),
      ...(tabItem.questionLogic ? tabItem.questionLogic : []),
      ...(tabItem.blockLogic ? tabItem.blockLogic : []),
    ].some((item) => item.type !== "text");
  }, [tabItem]);

  return hasDecorators;
};

export default useHasDecorators;
