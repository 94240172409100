import {
  Alert,
  AlertTitle,
  Button,
  Chip,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import useSurveysStore from "../../../../store/surveys";
import useBaseStore from "../../../../store/base";
import { useEffect, useMemo, useState } from "react";
import { MuiFileInput } from "mui-file-input";
import { LoadingButton } from "@mui/lab";
import { CloudUpload } from "@mui/icons-material";
import getJwt from "../../../../helpers/getJwt";
import { API } from "aws-amplify";
import { AVAILABLE_LOCALES } from "../../../../helpers/constants";

const UploadTranslations = () => {
  const currentSurvey = useSurveysStore((state) => state.currentSurvey);
  const setSnackbar = useBaseStore((state) => state.setSnackbar);

  const [dialog, setDialog] = useState(false);
  const [locale, setLocale] = useState("es");
  const [loader, setLoader] = useState(false);
  const [file, setFile] = useState(null);
  const [spreadsheetId, setSpreadsheetId] = useState("");
  const [uploadMethod, setUploadMethod] = useState("file");

  useEffect(() => {
    if (!dialog) {
      setUploadMethod("file");
      setSpreadsheetId("");
      setFile(null);
    }
  }, [dialog]);

  const localeValue = useMemo(() => {
    return AVAILABLE_LOCALES.find((l) => l.id === locale)?.value;
  }, [locale]);

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsText(file);

      reader.onload = (e) => {
        try {
          const content = JSON.parse(e.target.result);
          resolve(content);
        } catch (error) {
          reject(error);
        }
      };
    });
  };

  const handleUpload = async () => {
    setLoader(true);

    try {
      const jwt = await getJwt();

      if (uploadMethod === "file") {
        const fileContent = await readFile(file);

        await API.put("translations", "/localiser", {
          body: {
            locale,
            source: currentSurvey.name,
            values: fileContent,
          },
          headers: { Authorization: `Bearer ${jwt}` },
        }); // uploads the content provided from the translators for the selected survey and locale
      }

      if (uploadMethod === "spreadsheet") {
        await API.post("editor", "/translations/uploader", {
          body: {
            survey: currentSurvey.name,
            locale,
            spreadsheetId,
          },
          headers: { Authorization: `Bearer ${jwt}` },
        });
      }

      setDialog(false);

      setSnackbar({
        open: true,
        type: "success",
        message:
          "Translations were successfully uploaded to translations database!",
      });
    } catch (e) {
      console.error(e);

      setSnackbar({
        open: true,
        type: "error",
        message: "Error while uploading translations",
      });
    }

    setLoader(false);
  };

  return (
    <Stack sx={{ alignItems: "center" }}>
      <Stack direction="row" sx={{ justifyContent: "center" }} gap={2}>
        <FormControl size="small">
          <InputLabel id="demo-simple-select-label">Locale</InputLabel>

          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={locale}
            label="Locale"
            onChange={(event) => setLocale(event.target.value)}
          >
            {AVAILABLE_LOCALES.map((locale) => (
              <MenuItem key={locale.id} value={locale.id}>
                {locale.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button variant="outlined" onClick={() => setDialog(true)}>
          3. Upload content
        </Button>
      </Stack>

      <Dialog onClose={() => setDialog(false)} open={dialog}>
        <Stack sx={{ width: "600px", padding: 2 }} gap={4}>
          <Stack
            direction="row"
            sx={{ alignItems: "center", justifyContent: "center" }}
            gap={1}
          >
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Selected Locale:
            </Typography>

            <Chip label={localeValue} size="small" />
          </Stack>

          <Tabs
            value={uploadMethod}
            onChange={(_event, newValue) => setUploadMethod(newValue)}
            variant="fullWidth"
          >
            <Tab value="file" label="File" />
            <Tab value="spreadsheet" label="Spreadsheet" />
          </Tabs>

          {uploadMethod === "file" && (
            <MuiFileInput
              value={file}
              onChange={(newValue) => setFile(newValue)}
              label="Upload content"
              size="small"
            />
          )}

          {uploadMethod === "spreadsheet" && (
            <TextField
              label="Spreadsheet ID"
              variant="outlined"
              value={spreadsheetId}
              size="small"
              onChange={(e) => setSpreadsheetId(e.target.value)}
            />
          )}

          <Alert severity="warning">
            <AlertTitle>Warning</AlertTitle>
            Make sure you provide a translation{" "}
            {uploadMethod === "file" ? "file" : "spreadsheet"} correspoding to
            the selected locale.
          </Alert>

          <LoadingButton
            loading={loader}
            variant="outlined"
            onClick={handleUpload}
            startIcon={<CloudUpload />}
            disabled={
              (uploadMethod === "file" && !file) ||
              (uploadMethod === "spreadsheet" && !spreadsheetId)
            }
          >
            Upload
          </LoadingButton>
        </Stack>
      </Dialog>
    </Stack>
  );
};

export default UploadTranslations;
