import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

const initialState = {
  editorSurvey: null,
  editorSurveyFetched: false,
  initialEditorSurvey: null,
  importErrorMsg: null,
  sheetId: null,
  filters: {
    questions: {
      name: "",
      status: [],
      sector: [],
      type: [],
      owner: [],
      required: "",
      hasLogic: "",
      implementedLogic: "",
    },
    blocks: {
      status: [],
      name: "",
      hasLogic: "",
      implementedLogic: "",
    },
  },
  worker: null,
};

const useEditorStore = create()(
  immer((set) => ({
    ...initialState,
    setEditorSurvey: (editorSurvey) =>
      set((state) => {
        state.editorSurvey = editorSurvey;
      }),
    setEditorSurveyFetched: (editorSurveyFetched) =>
      set((state) => {
        state.editorSurveyFetched = editorSurveyFetched;
      }),
    setInitialEditorSurvey: (initialEditorSurvey) =>
      set((state) => {
        state.initialEditorSurvey = initialEditorSurvey;
      }),
    setImportErrorMsg: (importErrorMsg) =>
      set((state) => {
        state.importErrorMsg = importErrorMsg;
      }),
    setSheetId: (sheetId) =>
      set((state) => {
        state.sheetId = sheetId;
      }),
    setFilters: ({ tab, filterKey, value }) =>
      set((state) => {
        state.filters[tab][filterKey] = value;
      }),
    clearFilters: (tab) =>
      set((state) => {
        state.filters[tab] = initialState.filters[tab];
      }),
    updateSurveyTab: ({ tab, content }) =>
      set((state) => {
        state.editorSurvey[tab] = content;
      }),
    setWorker: (worker) =>
      set((state) => {
        state.worker = worker;
      }),
    reset: () => set(initialState),
  }))
);

export const tabsWithSearch = ["blocks", "questions"];

export const useTabItem = (tab, itemId) =>
  useEditorStore((state) =>
    state.editorSurvey[tab]?.find((i) => i.name === itemId)
  );

export default useEditorStore;
