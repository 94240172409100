import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useMemo, useState } from "react";
import useSurveysStore from "../../../../store/surveys";
import { API } from "aws-amplify";
import getJwt from "../../../../helpers/getJwt";
import extractor from "../../../../helpers/extractor";
import { find, map } from "lodash";
import { CloudUpload } from "@mui/icons-material";
import { AVAILABLE_LOCALES } from "../../../../helpers/constants";
import useBaseStore from "../../../../store/base";

const Localisation = () => {
  const currentSurvey = useSurveysStore((state) => state.currentSurvey);
  const setSnackbar = useBaseStore((state) => state.setSnackbar);

  const [locale, setLocale] = useState("es");
  const [loader, setLoader] = useState(false);
  const [dialog, setDialog] = useState(false);

  const localeValue = useMemo(() => {
    return AVAILABLE_LOCALES.find((l) => l.id === locale)?.value;
  }, [locale]);

  const handleLocalise = async () => {
    setLoader(true);

    try {
      const jwt = await getJwt();

      const localeData = await API.get(
        "translations",
        `/localiser?locale=${locale}&source=${currentSurvey.name}`,
        {
          headers: { Authorization: `Bearer ${jwt}` },
        }
      ); // get the survey's contents for the selected locale from translations DB

      const { pages, questions } = await API.get(
        "surveys",
        `/surveys/name/${currentSurvey.name}/exporter`
      ); // get survey data

      const templateData = extractor({ pages, questions }); // map the result to the appropriate format.

      const result = map(templateData, (templateItem) => {
        // for each item of the templateData
        const value = find(localeData, { reference: templateItem.value }); // find a matching value from the localised data
        return {
          name: templateItem.name,
          value: value ? value.content : templateItem.value, // return the localised value or provide the current (english) value as fallback
        };
      });

      await API.put(
        "surveys",
        `/surveys/${currentSurvey.id}/locale/${locale}/importer`,
        {
          body: result,
          headers: { Authorization: `Bearer ${jwt}` },
        }
      ); // upload localized version of survey

      setDialog(false);

      setSnackbar({
        open: true,
        type: "success",
        message: "Localised survey was uploaded!",
      });
    } catch (e) {
      console.error(e);

      setSnackbar({
        open: true,
        type: "error",
        message: "Error while uploading the localised survey",
      });
    }

    setLoader(false);
  };

  return (
    <Stack
      sx={{
        flexGrow: 1,
        pt: 8,
      }}
      gap={4}
    >
      <Stack direction="row" sx={{ justifyContent: "center" }} gap={2}>
        <FormControl size="small">
          <InputLabel id="demo-simple-select-label">Locale</InputLabel>

          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={locale}
            label="Locale"
            onChange={(event) => setLocale(event.target.value)}
          >
            {AVAILABLE_LOCALES.map((locale) => (
              <MenuItem key={locale.id} value={locale.id}>
                {locale.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button variant="outlined" onClick={() => setDialog(true)}>
          Upload localisation
        </Button>
      </Stack>

      <Dialog onClose={() => setDialog(false)} open={dialog}>
        <Stack sx={{ width: "600px", padding: 2 }} gap={2}>
          <Alert severity="info">
            <AlertTitle>Info</AlertTitle>
            You are about to upload a localised version of survey{" "}
            <strong>{currentSurvey.name}</strong> in{" "}
            <strong>{localeValue}</strong>
          </Alert>

          <LoadingButton
            loading={loader}
            variant="outlined"
            onClick={handleLocalise}
            startIcon={<CloudUpload />}
          >
            Upload
          </LoadingButton>
        </Stack>
      </Dialog>
    </Stack>
  );
};

export default Localisation;
