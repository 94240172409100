import { Box, Chip, Typography } from "@mui/material";
import React from "react";

const KeyValueChip = ({ ChipKey, ChipValue }) => {
  return (
    <Chip
      sx={{
        height: "auto",
        "& .MuiChip-label": {
          display: "block",
          whiteSpace: "normal",
        },
      }}
      label={
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2" sx={{ mr: 1, fontWeight: 500 }}>
            {ChipKey}:
          </Typography>

          <Typography variant="body2">{ChipValue}</Typography>
        </Box>
      }
    />
  );
};

export default KeyValueChip;
