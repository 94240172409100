import { Button, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { API } from "aws-amplify";
import getJwt from "../../helpers/getJwt";
import useSurveysStore from "../../store/surveys";
import useBaseStore from "../../store/base";
import { LoadingButton } from "@mui/lab";

const DeleteSurvey = ({ survey, onClose }) => {
  const [deleteInput, setDeleteInput] = useState("");
  const [loader, setLoader] = useState(false);
  const { surveys, setSurveys } = useSurveysStore();
  const { setSnackbar } = useBaseStore();

  const handleDelete = async () => {
    setLoader(true);

    try {
      const jwt = await getJwt();

      await API.del("surveys", `/surveys/${survey._id}`, {
        headers: { Authorization: `Bearer ${jwt}` },
      });

      localStorage.removeItem(survey._id);

      setSurveys(surveys.filter((s) => s._id !== survey._id));

      setSnackbar({
        open: true,
        type: "success",
        message: "Survey was deleted.",
      });

      onClose();
    } catch (e) {
      console.log(e);

      setSnackbar({
        open: true,
        type: "error",
        message: "Something went wrong",
      });
    }

    setLoader(false);
  };

  return (
    <Stack
      sx={{
        flexGrow: 1,
        alignItems: "center",
      }}
      gap={2}
    >
      <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
        Are you sure you want to delete survey "<strong>{survey.name}</strong>"
        ?
      </Typography>

      <Typography variant="body2" sx={{ textAlign: "center" }}>
        To confirm deletion, type <strong>delete {survey.name}</strong> in the
        field.
      </Typography>

      <TextField
        size="small"
        placeholder={`delete ${survey.name}`}
        value={deleteInput}
        onChange={(e) => setDeleteInput(e.target.value)}
        variant="outlined"
      />

      <Stack direction="row" gap={1}>
        <Button size="small" onClick={onClose}>
          Cancel
        </Button>

        <LoadingButton
          size="small"
          color="error"
          variant="contained"
          disabled={deleteInput !== `delete ${survey.name}`}
          onClick={handleDelete}
          loading={loader}
        >
          Delete
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default DeleteSurvey;
