import { Stack, Divider } from "@mui/material";
import UploadUntranslatedContent from "./UploadUntranslatedContent";
import DownloadUntranslatedContent from "./DownloadUntraslatedContent";
import UploadTranslations from "./UploadTranslations";
import GenerateAllContent from "./GenerateAllContent";

const Translations = () => {
  return (
    <Stack
      sx={{
        flexGrow: 1,
        pt: 8,
      }}
      gap={4}
    >
      <UploadUntranslatedContent />

      <Divider />

      <DownloadUntranslatedContent />

      <Divider />

      <UploadTranslations />

      <Divider />

      <GenerateAllContent />
    </Stack>
  );
};

export default Translations;
