import {
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMount } from "react-use";
import dayjs from "dayjs";
import { Close, Delete } from "@mui/icons-material";
import useSurveysStore from "../../store/surveys";
import useBaseStore from "../../store/base";

const Surveys = () => {
  const { surveys, fetchSurveys } = useSurveysStore();
  const { setDialog } = useBaseStore();
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();

  useMount(() => {
    (async () => {
      try {
        await fetchSurveys();
      } catch (e) {
        console.log(e);
      }

      setLoader(false);
    })();
  });

  const filteredSurveys = useMemo(() => {
    if (!search) return surveys;

    return surveys.filter((survey) =>
      survey.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [surveys, search]);

  return (
    <Stack
      sx={{
        flexGrow: 1,
      }}
    >
      {loader ? (
        <CircularProgress thickness={2} size="80px" sx={{ m: "auto" }} />
      ) : (
        <>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="secondary"
                component={Link}
                to="/surveys/create"
              >
                Create Survey
              </Button>
            </Grid>

            <Grid item xs={6} sx={{ display: "flex" }}>
              <Stack
                direction="row"
                gap={1}
                sx={{ alignItems: "center", flexGrow: 1 }}
              >
                <TextField
                  label="Search a survey"
                  variant="outlined"
                  sx={{ flexGrow: 1 }}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />

                {search && (
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => setSearch("")}
                  >
                    <Close />
                  </IconButton>
                )}
              </Stack>
            </Grid>
          </Grid>
          <DataGrid
            disableRowSelectionOnClick
            disableColumnMenu
            onRowClick={(params) => navigate(`/surveys/${params.row._id}`)}
            sx={{
              "& .MuiDataGrid-row": {
                cursor: "pointer",
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
                outline: "none !important",
              },
            }}
            rows={filteredSurveys}
            columns={[
              { field: "name", headerName: "Name", flex: 1 },
              {
                field: "startDate",
                headerName: "Start Date",
                flex: 1,
                renderCell: (params) => {
                  return (
                    <div>
                      {dayjs.utc(params.value).format("MMM DD YYYY | HH:mm")}
                    </div>
                  );
                },
              },
              {
                field: "endDate",
                headerName: "End Date",
                flex: 1,
                renderCell: (params) => {
                  return (
                    <div>
                      {dayjs.utc(params.value).format("MMM DD YYYY | HH:mm")}
                    </div>
                  );
                },
              },
              {
                field: "status",
                headerName: "Status",
                flex: 1,
                sortable: false,
                renderCell: (params) => {
                  return (
                    <Chip
                      label={params.value}
                      sx={{
                        textTransform: "capitalize",
                      }}
                      color={params.value === "draft" ? "warning" : "success"}
                      size="small"
                    />
                  );
                },
              },
              {
                field: "actions",
                headerName: "Actions",
                flex: 1,
                sortable: false,
                renderCell: (params) => {
                  return (
                    <Button
                      size="small"
                      variant="outlined"
                      startIcon={<Delete />}
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation();

                        setDialog({
                          open: true,
                          component: "deleteSurvey",
                          props: {
                            survey: params.row,
                          },
                        });
                      }}
                    >
                      Delete
                    </Button>
                  );
                },
              },
            ]}
            pageSize={10}
            rowsPerPageOptions={[10, 50, 100]}
          />
        </>
      )}
    </Stack>
  );
};

export default Surveys;
