import { CloudUpload } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { useMatch } from "react-router-dom";
import useSurveysStore from "../../../../store/surveys";
import useEditorStore from "../../../../store/editor";
import getJwt from "../../../../helpers/getJwt";
import useBaseStore from "../../../../store/base";
import { LoadingButton } from "@mui/lab";

const UploadSurvey = ({ onClose }) => {
  const { currentSurvey } = useSurveysStore();
  const { editorSurvey, worker } = useEditorStore();
  const { setSnackbar } = useBaseStore();
  const [loader, setLoader] = useState();
  const match = useMatch("/surveys/:id/editor/*");

  useEffect(() => {
    if (!match) onClose();
  }, [match, onClose]);

  const handleUpload = async () => {
    setLoader(true);

    try {
      const compiled = await worker.compile(editorSurvey);

      const jwt = await getJwt();

      await API.put("surveys", `/surveys/${currentSurvey._id}/importer`, {
        body: {
          ...compiled,
        },
        headers: { Authorization: `Bearer ${jwt}` },
      });

      setSnackbar({
        open: true,
        type: "success",
        message: "Deployed Successfully!",
      });
    } catch (e) {
      console.error(e);

      if (e.message !== "CompilerError")
        setSnackbar({
          open: true,
          type: "error",
          message: "Error on upload",
        });
    }

    onClose();

    setLoader(false);
  };

  return (
    <Stack
      sx={{
        flexGrow: 1,
        alignItems: "center",
      }}
      gap={2}
    >
      <CloudUpload color="primary" />

      <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
        Are you sure you want deploy spreadsheet "
        <strong>{editorSurvey.title}</strong>" to survey "
        <strong>{currentSurvey.name}</strong>" ?
      </Typography>

      <Stack direction="row" gap={1}>
        <Button size="small" onClick={onClose}>
          Cancel
        </Button>

        <LoadingButton
          loading={loader}
          size="small"
          color="primary"
          variant="contained"
          onClick={handleUpload}
        >
          Deploy
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default UploadSurvey;
