import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useMemo } from "react";

const Comments = ({ comments }) => {
  const commentLines = useMemo(() => {
    return comments ? comments?.split("\n") : null;
  }, [comments]);

  return (
    <>
      {commentLines ? (
        <Accordion sx={{ width: "400px" }}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography sx={{ fontWeight: 500 }}>Comments</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <List dense>
              {commentLines.map((line, index) => {
                return (
                  <ListItem key={line + index}>
                    <ListItemText primary={line} />
                  </ListItem>
                );
              })}
            </List>
          </AccordionDetails>
        </Accordion>
      ) : (
        <></>
      )}
    </>
  );
};

export default Comments;
