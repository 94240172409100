import {
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { API } from "aws-amplify";
import getJwt from "../../helpers/getJwt";
import useThemesStore from "../../store/themes";
import useBaseStore from "../../store/base";

const DeleteTheme = ({ theme, onClose }) => {
  const [deleteInput, setDeleteInput] = useState("");
  const [loader, setLoader] = useState(false);
  const { setSnackbar } = useBaseStore();
  const { themes, setThemes } = useThemesStore();

  const handleDelete = async () => {
    setLoader(true);

    try {
      const jwt = await getJwt();

      const res = await API.del("editor", `/themes/${theme.id}`, {
        headers: { Authorization: `Bearer ${jwt}` },
      });

      if (res.deleted) setThemes(themes.filter((t) => t.id !== theme.id));

      setSnackbar({
        open: true,
        type: "success",
        message: "Theme was deleted.",
      });

      onClose();
    } catch (e) {
      console.log(e);

      setSnackbar({
        open: true,
        type: "error",
        message: "Something went wrong",
      });
    }

    setLoader(false);
  };

  return (
    <Stack
      sx={{
        flexGrow: 1,
        alignItems: "center",
      }}
      gap={2}
    >
      <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
        Are you sure you want to delete theme "<strong>{theme.name}</strong>" ?
      </Typography>

      <Typography variant="body2" sx={{ textAlign: "center" }}>
        To confirm deletion, type <strong>delete {theme.name}</strong> in the
        field.
      </Typography>

      <TextField
        size="small"
        placeholder={`delete ${theme.name}`}
        value={deleteInput}
        onChange={(e) => setDeleteInput(e.target.value)}
        variant="outlined"
      />

      <Stack direction="row" gap={1}>
        <Button size="small" onClick={onClose}>
          Cancel
        </Button>

        {loader ? (
          <CircularProgress
            size="30px"
            color="error"
            sx={{ alignSelf: "center" }}
          />
        ) : (
          <Button
            size="small"
            color="error"
            variant="contained"
            disabled={deleteInput !== `delete ${theme.name}`}
            onClick={handleDelete}
          >
            Delete
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default DeleteTheme;
