import { useParams } from "react-router-dom";
import useEditorStore, { tabsWithSearch } from "../store/editor";
import { useMemo } from "react";

const useFilters = () => {
  const { editorSurvey, filters } = useEditorStore();
  const { tab } = useParams();

  const activeFilterCount = useMemo(() => {
    if (!tabsWithSearch.includes(tab)) return;

    let count = 0;

    Object.keys(filters[tab]).forEach((key) => {
      if (key === "name") return;

      const filter = filters[tab][key];

      if (Array.isArray(filter)) {
        if (filter.length) count++;
      } else {
        if (filter) count++;
      }
    });

    return count;
  }, [filters, tab]);

  const listContent = useMemo(() => {
    if (!editorSurvey[tab]) return [];

    return editorSurvey[tab].map((t) => {
      return {
        name: t.name,
        type: t.type,
        title: t.title,
        required: t.required,
        owner: t?.__metadata__?.owner,
        status: t?.__metadata__?.status,
        sector: t?.__metadata__?.sector,
        hasLogic:
          t?.__metadata__?.rawQuestionLogic || t.__metadata__?.rawBlockLogic,
        implementedLogic: Boolean(t?.logicDefinitions?.length > 0),
      };
    });
  }, [tab, editorSurvey]);

  const filteredListContent = useMemo(() => {
    if (!activeFilterCount && !filters[tab]?.name) return listContent;

    const filtered = listContent.filter((c) => {
      let fulfillsRequired = false;
      let fulfillsType = false;
      let fulfillsSector = false;
      let fulfillsOwner = false;
      let fulfillsHasLogic = false;
      let fulfillsImplementedLogic = false;
      let fulfillsStatus = false;
      let fulfillsName = false;

      if (
        (filters[tab]?.required === "YES" && c.required) ||
        (filters[tab]?.required === "NO" && !c.required) ||
        !filters[tab]?.required
      )
        fulfillsRequired = true;

      if (
        (filters[tab]?.hasLogic === "YES" && c.hasLogic) ||
        (filters[tab]?.hasLogic === "NO" && !c.hasLogic) ||
        !filters[tab]?.hasLogic
      )
        fulfillsHasLogic = true;

      if (
        (filters[tab]?.implementedLogic === "YES" && c.implementedLogic) ||
        (filters[tab]?.implementedLogic === "NO" && !c.implementedLogic) ||
        !filters[tab]?.implementedLogic
      )
        fulfillsImplementedLogic = true;

      if (
        filters[tab]?.type?.indexOf(c.type) > -1 ||
        !filters[tab]?.type?.length
      )
        fulfillsType = true;

      if (
        filters[tab]?.sector?.indexOf(c.sector) > -1 ||
        !filters[tab]?.sector?.length
      )
        fulfillsSector = true;

      if (
        filters[tab]?.owner?.indexOf(c.owner) > -1 ||
        !filters[tab]?.owner?.length
      )
        fulfillsOwner = true;

      if (
        filters[tab]?.status?.indexOf(c.status) > -1 ||
        !filters[tab]?.status?.length
      )
        fulfillsStatus = true;

      if (
        c.name.toLowerCase().includes(filters[tab]?.name?.toLowerCase()) ||
        !filters[tab]?.name
      )
        fulfillsName = true;

      return (
        fulfillsRequired &&
        fulfillsType &&
        fulfillsSector &&
        fulfillsOwner &&
        fulfillsName &&
        fulfillsHasLogic &&
        fulfillsStatus &&
        fulfillsImplementedLogic
      );
    });

    return filtered;
  }, [listContent, tab, activeFilterCount, filters]);

  return { filteredListContent, activeFilterCount };
};

export default useFilters;
