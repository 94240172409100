import { Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { createSearchParams, useNavigate } from "react-router-dom";
import useSurveysStore from "../../../../../store/surveys";
import { v4 as uuidv4 } from "uuid";

const Questions = ({ list, from }) => {
  const { currentSurvey } = useSurveysStore();
  const navigate = useNavigate();

  return (
    <Stack
      sx={{
        flexGrow: 1,
        alignSelf: "stretch",
      }}
    >
      <DataGrid
        disableRowSelectionOnClick
        density="compact"
        disableColumnMenu
        getRowId={() => {
          return uuidv4();
        }}
        sx={{
          "& .MuiDataGrid-row": {
            cursor: "pointer",
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
            outline: "none !important",
          },
        }}
        rows={list}
        columns={[
          { field: "name", headerName: "ID", flex: 1 },
          { field: "status", headerName: "Status", flex: 1 },
          { field: "sector", headerName: "Sector", flex: 1 },
          { field: "type", headerName: "Type", flex: 1 },
          { field: "owner", headerName: "Owner", flex: 1 },
          {
            field: "required",
            headerName: "Required",
            flex: 1,
            renderCell: (params) => {
              return <Typography>{params.value ? "YES" : "NO"}</Typography>;
            },
          },
          {
            field: "hasLogic",
            headerName: "Has Logic",
            flex: 1,
            renderCell: (params) => {
              return <Typography>{params.value ? "YES" : "NO"}</Typography>;
            },
          },
          {
            field: "implementedLogic",
            headerName: "Implemented Logic",
            flex: 1,
            renderCell: (params) => {
              return (
                <Typography>
                  {params.row.hasLogic ? (params.value ? "YES" : "NO") : "-"}
                </Typography>
              );
            },
          },
        ]}
        onRowClick={(row) => {
          navigate({
            pathname: `/surveys/${currentSurvey._id}/editor/questions/${row.row.name}`,
            search: from ? createSearchParams({ from }).toString() : "",
          });
        }}
      />
    </Stack>
  );
};

export default Questions;
